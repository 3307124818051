import React, { useEffect } from 'react';
import WebinarPage from '../components/webinar-page';
import '../styles/webinar-page.css';
///////////////////////////////////////////////////////////////////////////////////////////////////////////
const WebinarDemystifying = () => {
  //
  const webinarUrl = "https://webinarkit.com/webinar/registration/oneclick/67bc3875e2aace524737af7a?date=jit_15";
  const title = "Patent Claims Decoded 2025: Understanding the Heart of Your Patent";
  const canonical = "/webinar-demystifying-patent-claims-and-specifications";
  const introAuthor = "Bao Tran is a patent specialist with expertise in AI patenting at PatentPC."
  const lessons = [
    "How to draft clear and enforceable patent claims.",
    "The role of specifications in strengthening patent applications.",
    "Techniques to prevent claim rejections and legal loopholes.",
    "Expert advice on effective communication with patent examiners."
  ]
  const btnTxt = "Watch Webinar Now";
  useEffect(() => {
    document?.body.classList.add('no-home');
    //
  }, []);

  return (
    <>
    <WebinarPage webinarUrl={webinarUrl} introAuthor={introAuthor} title={title} canonical={canonical} lessons={lessons} btnTxt={btnTxt}></WebinarPage>
    </>
  )
};

export default WebinarDemystifying;